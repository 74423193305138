@import url(https://fonts.googleapis.com/css2?family=Teko&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Geostar&display=swap);
@import url(https://fontlibrary.org/face/dseg14);
html,
body {
  margin: 0;
  height: 100%;
  background: #000;
}

.wrapper,
.canvas,
.gesture-wrapper,
.offcanvas,
.offcanvas__main,
.offcanvas__menu,
.offcanvas__clock-wrapper {
  height: 100%;
}

.app {
  background: #000;
  color: #fff;
  font-family: sans-serif;
  height: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
}

.app__starter {
  font-size: 120px;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9)
}

.clock-basic--digital {
  font-family: "DSEG14ClassicBoldItalic", sans-serif;
  color: hsl(0 80% 45%);
  line-height: 1;
}

.clock-basic--digital .clock-basic__date {
  font-size: 0.35em;
  line-height: 1;
  display: block;
  margin: 0.5em 0 0 .2em;
  font-family: "Teko", sans-serif;
}

.clock-basic--super-digital {
  font-family: "DSEG14ClassicItalic", sans-serif;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #ff4444, 0 0 0.5em #ff4444, 0 0 0.1em #ff4444, 0 10px 3px #000;
  color: #fee;
  line-height: 1;
}

.clock-basic--super-digital .clock-basic__date {
  font-size: 0.25em;
  line-height: 1;
  display: block;
  margin: 0.8em 0 0 1.2em;
  font-family: "Teko", sans-serif;
}



.clock-basic--alexa {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  color: hsl(30deg 80% 45%);
  text-align: center;
}

.clock-basic--alexa .clock-basic__date {
  font-size: 0.2em;
  font-weight: 400;
  margin-top: 0.1em;
  text-transform: uppercase;
}



.clock-basic--modernist {
  font-family: 'Geostar', sans-serif;
  letter-spacing: 0.15em;
  text-align: center;
}

.clock-basic--modernist .clock-basic__date {
  font-family: 'Roboto Mono', sans-serif;
  letter-spacing: -.04em;
  font-size: 0.25em;
}

.gesture-wrapper {
    touch-action: none;
}

.oled-saver__child {
    display: inline-block;
    padding: 20px;
}

.oled-saver--active {
    transition: transform 2s ease;
    will-change: transform;
}

.offcanvas--active .oled-saver {
    transition: transform 0.5s ease;
    transform: translate(0, 50%) !important;
}

.offcanvas__menu {
    position: absolute;
    background: #303030;
    width: 50%;
    touch-action: auto;
}

.offcanvas__main {
    background: #000;
    position: relative;
    box-shadow: 0 0px 13.7px rgba(0, 0, 0, 0.104),
        0 0px 25.1px rgba(0, 0, 0, 0.132), 0 0px 40px rgba(0, 0, 0, 0.179),
        0 0px 88px rgba(90, 89, 89, 0.4);
}

.offcanvas__clock-wrapper {
    transform-origin: left center;
}

.offcanvas--still .offcanvas__main,
.offcanvas--still .offcanvas__clock-wrapper {
    transition: transform 0.3s ease;
}

